import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import { Button } from 'semantic-ui-react';
import { red, green } from '@material-ui/core/colors';
import { FormatNumber } from '../../../utils/FormatInputs';

const useStyles = makeStyles({
    alignButton: { textAlign: 'center', justifyContent: 'center', zIndex: 100 },
    gap: { gap: "0px !important" }
});

function ViewProfesionalBillDetails({
    openModal,
    HandleClose,
    dataToRender
}) {
    const classes = useStyles();

    return <Dialog open={openModal} onClose={HandleClose}>
        {dataToRender && <>
            <DialogTitle id='form-dialog-title'>Detalle de la liquidación ({dataToRender.profesional.nombre})</DialogTitle>
            <DialogContent className={classes.gap}>
                {dataToRender.facturasPorDetalleLiquidacion.length > 0 &&
                    <div>
                        <strong style={{ color: green[700] }}>Facturas:</strong>
                        <ul>
                            {dataToRender.facturasPorDetalleLiquidacion.map((factura, index) => {
                                // Sumar todos los montos de débitos
                                const totalDebitos = factura.debitosPorFacturaPorDetalleLiquidacion?.reduce((total, debito) => total + debito.monto, 0) || 0;

                                // Sumar el total de débitos al montoDescuento
                                const montoDescuentoFinal = factura.montoDescuento + totalDebitos;

                                return (
                                    <li key={index}>
                                        {factura.obraSocial.nombre}, Periodo: {factura.periodo} | Importe: {FormatNumber({ style: 'currency', currency: '$', value: factura.montoTotal })} | Débito Total: {FormatNumber({ style: 'currency', currency: '$', value: montoDescuentoFinal })} |
                                        Total: {FormatNumber({ style: 'currency', currency: '$', value: factura.montoTotal - montoDescuentoFinal })} | N° Factura: {factura.facturaId}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                }
                {dataToRender.acreditacionesPorDetalleLiquidacion.length > 0 &&
                    <div>
                        <strong style={{ color: green[700] }}>Acreditaciones:</strong>
                        <ul>
                            {dataToRender.acreditacionesPorDetalleLiquidacion.map((acreditacion, index) => (
                                <li key={index}>
                                    Acreditación de Saldo: {FormatNumber({ style: 'currency', currency: '$', value: acreditacion.monto })}
                                </li>
                            ))}
                        </ul>
                    </div>
                }
                {dataToRender.facturasPorDetalleLiquidacion?.some(factura => factura.debitosPorFacturaPorDetalleLiquidacion.length > 0 || factura.montoDescuento > 0) &&
                    <div>
                        <strong style={{ color: red[700] }}>Débitos:</strong>
                        <ul>
                            {dataToRender.facturasPorDetalleLiquidacion.map((factura, facturaIndex) => (
                                <React.Fragment key={facturaIndex}>
                                    {factura.montoDescuento > 0 && <li> Factura N° {factura.facturaId}, Monto {FormatNumber({ style: 'currency', currency: '$', value: factura.montoDescuento })} , Motivo "Débito Automático"</li>}
                                    {factura.debitosPorFacturaPorDetalleLiquidacion.map((debito, debitoIndex) => (
                                        <li key={`${facturaIndex}-${debitoIndex}`}>
                                            Factura N° {factura.facturaId}, Monto ${debito.monto}, Motivo "{debito.motivo}"
                                        </li>
                                    ))}
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                }
                {dataToRender.descuentosPorDetalleLiquidacion.length > 0 &&
                    <div>
                        <strong style={{ color: red[700] }}>Descuentos:</strong>
                        <ul>
                            {dataToRender.descuentosPorDetalleLiquidacion.map((descuento, index) => (
                                <li key={index}>
                                    {descuento.nombre}: {FormatNumber({ style: 'currency', currency: '$', value: descuento.monto })} {descuento.esRetencionGanancia == true ? ` | N° Retención: ${descuento.numeroRetencion}` : ""}
                                </li>
                            ))}
                        </ul>
                    </div>
                }
            </DialogContent>
            <DialogActions className={classes.alignButton}>
                <Button onClick={HandleClose}>Cerrar</Button>
            </DialogActions>
        </>}
    </Dialog>
}

export default ViewProfesionalBillDetails